import { Injectable, NgZone } from '@angular/core';
import { debounceTime, map, Observable, startWith } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ResizeService {
  resize$: Observable<boolean>;

  constructor(private ngZone: NgZone) {
    this.resize$ = new Observable((observer) => {
      const resizeHandler = () => {
        this.ngZone.run(() => observer.next());
      };

      window.addEventListener('resize', resizeHandler);

      return () => window.removeEventListener('resize', resizeHandler);
    }).pipe(
      startWith(this.isMobileView()),
      debounceTime(200),
      map(() => this.isMobileView())
    );
  }

  isMobileView(): boolean {
    return window.innerWidth <= 768;
  }
}
